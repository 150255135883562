import cn from 'classnames';
import { find, sortBy } from 'lodash';
import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import defaultMenuLogo from '../../../assets/cai_logo_2c_white.svg';
import { MaterialIcon } from '../../../components/icons';
import { Translate, useTranslateMessage } from '../../../components/localization';
import { useAppData } from '../../../util/app-data/AppData';
import { AppContext, useAppContext } from '../../../util/app-provider/context';

import { useEntityContext } from '../../../util/entity-provider/context';
import { isProduction } from '../../../util/isProduction';

import SearchFilter, { getFilteredDataByProperties } from '../../../components/form/search-filter';
import { SidebarContainer, MobileSubMenuContainer } from './styles';
import { Drawer, Box, useMediaQuery } from '@mui/material';

const SORT_VALUES = [
  { iconName: 'SortByAlpha', sortKey: 'alpha' },
  { iconName: 'FormatListNumbered', sortKey: 'default' },
];

const NewSidebar = ({ pathPrefix }) => {
  const translateMessage = useTranslateMessage();
  const [activatedSubMenu, setActivatedSubMenu] = useState(null);

  const [activatedTooltip, setActivatedTooltip] = useState(null);

  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { isSidebarOpen, toggleSidebar } = useContext(AppContext);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const toggleSubMenuOpen = () => {
    setIsSubMenuOpen(prevState => !prevState);
  };
  const { appConfig } = useAppContext();

  const [enableSearch, setEnableSearch] = useState(false);

  const { entityRef } = useEntityContext();
  const { menuConfig: initialMenuConfig } = useAppData();
  const { menuConfig, setCurrentFeature } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const preparedConfig = useMemo(() => {
    if (!menuConfig) {
      return [];
    }
    const isProd = isProduction();

    return menuConfig
      ?.map?.(configItem => configItem || undefined)
      ?.filter?.(configItem => (isProd ? !configItem.hideInProduction : true))
      ?.map?.(configItem => {
        if (configItem?.children?.length) {
          return {
            ...configItem,
            children: configItem.children?.map(child => ({
              ...child,
              path: `${configItem.path}${child.path}`,
            })),
          };
        }
        return configItem;
      });
  }, [menuConfig]);

  const determineCurrentFeature = React.useCallback(() => {
    const flattenedMenuConfig = menuConfig?.reduce?.((acc, item) => {
      if (item?.children?.length) {
        return [...acc, ...item.children?.map(child => ({ ...child, path: `${item.path}${child.path}` }))];
      }
      return [...acc, item];
    }, []);

    const matched = matchPath(
      { path: `${pathPrefix || ''}${appConfig?.isEntitySpecific ? '/:entityRef' : ''}/:path/*` },
      location.pathname
    );

    const targetPath =
      `${matched?.params?.path || ''}${matched?.params?.['*'] ? `/${matched?.params?.['*']}` : ''}` || '';

    const targetItem = find(flattenedMenuConfig, { path: `/${targetPath}` });
    setCurrentFeature(targetItem);
  }, [appConfig?.isEntitySpecific, location, menuConfig, setCurrentFeature, pathPrefix]);

  React.useEffect(() => {
    determineCurrentFeature();
  }, [determineCurrentFeature]);

  const handleMenuItemClick = useCallback(
    menuItemPath => {
      const destination = `${pathPrefix || ''}${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${menuItemPath}`;
      if (destination !== location.pathname) navigate(destination);
    },
    [appConfig?.isEntitySpecific, entityRef, location.pathname, navigate, pathPrefix]
  );

  const activateSubMenu = option => {
    if (option?.children?.length) {
      setActivatedSubMenu(option);
      setIsSubMenuOpen(true);
    }
  };

  const clearActivatedSubMenu = () => {
    setActivatedSubMenu(null);
    setIsSubMenuOpen(false);
  };

  const amIActive = React.useCallback(
    me =>
      location.pathname.startsWith(
        `${pathPrefix || ''}${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${me.path}`
      ),
    [appConfig?.isEntitySpecific, entityRef, location.pathname, pathPrefix]
  );

  const subMenuProps = {
    activatedSubMenu,
    isMobile,
    clearActivatedSubMenu,
    enableSearch,
  };

  const SubMenuContent = ({ activatedSubMenu, isMobile, clearActivatedSubMenu, enableSearch }) => {
    const [querySearch, setQuerySearch] = useState('');
    const [hideSortableSubMenu, setHideSortableSubMenu] = useState(true);
    const [sortMode, setSortMode] = useState('default');
    const sortedChildren = React.useMemo(() => {
      let menuItems =
        activatedSubMenu?.children?.map(child => ({ ...child, label: translateMessage(child?.labelStringId) })) || [];

      if (sortMode === 'default') {
        return menuItems;
      }
      return sortBy(menuItems || [], [
        function (o) {
          return o.label;
        },
      ]);
    }, [activatedSubMenu?.children, sortMode]);

    const filteredMenuOptions = useMemo(
      () => getFilteredDataByProperties({ data: sortedChildren, query: querySearch, properties: ['label'] }),
      [sortedChildren, querySearch]
    );

    useEffect(() => {
      setHideSortableSubMenu(activatedSubMenu?.hideSortControls);
      setSortMode(activatedSubMenu?.sortMode);
    }, [activatedSubMenu]);

    return (
      <>
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              'padding-left': '32px',
              'padding-top': '22px',
              gap: '10px',
            }}
            className={cn('menu-option', {
              'is-mobile': isMobile,
            })}
            onClick={() => {
              toggleSubMenuOpen();
              clearActivatedSubMenu();
            }}
          >
            <MaterialIcon iconName={'ArrowBackRounded'} />
            <Box component="span">Main Menu</Box>
          </Box>
        )}

        <div className="menu-title-container">
          {activatedSubMenu?.iconName ? <MaterialIcon iconName={activatedSubMenu?.iconName} /> : activatedSubMenu?.icon}
          <Translate stringId={activatedSubMenu?.labelStringId} />
        </div>
        {!hideSortableSubMenu ? (
          <div className="menu-order-container">
            {SORT_VALUES?.map(sortValue => (
              <div
                key={sortValue.sortKey}
                className={cn('menu-order-icon', { 'menu-order-icon__active': sortMode === sortValue.sortKey })}
                onClick={() => setSortMode(sortValue.sortKey)}
              >
                <MaterialIcon iconName={sortValue?.iconName} fontSize="small" />
              </div>
            ))}
          </div>
        ) : null}
        {enableSearch && (
          <div className="menu-search-container">
            <SearchFilter fullWidth={true} onSearchChange={setQuerySearch} querySearch={querySearch} />
          </div>
        )}
        <div className="menu-options-container">
          {filteredMenuOptions?.map((subMenuItem, index) => {
            return (
              <div
                key={index + subMenuItem?.labelStringId}
                className={cn('menu-option', { 'menu-option__active': amIActive(subMenuItem), 'is-mobile': isMobile })}
                onClick={() => {
                  handleMenuItemClick(subMenuItem.path);
                  clearActivatedSubMenu();
                  isMobile && toggleSidebar();
                }}
              >
                <Translate stringId={subMenuItem?.labelStringId} />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const SidebarHeader = () => (
    <div
      className={cn('sidebar-logo-container', {
        'is-mobile': isMobile,
      })}
    >
      <img src={initialMenuConfig?.menuLogo || defaultMenuLogo} alt="menu-logo" />
      {isMobile && (
        <MaterialIcon
          iconName={'MenuOpen'}
          onClick={() => {
            toggleSidebar() && clearActivatedSubMenu();
          }}
          className="menu-icon"
        />
      )}
    </div>
  );

  const SidebarContent = () => {
    return (
      <SidebarContainer
        className={cn('sidebar', {
          'has-children': activatedSubMenu?.children,
          'sidebar-secondary': initialMenuConfig?.sidebarVariant === 'secondary',
          'is-mobile': isMobile,
        })}
      >
        <SidebarHeader />
        <div
          className={cn('sidebar-options-container', {
            hidden: isSubMenuOpen && isMobile,
          })}
        >
          {preparedConfig?.map((option, index) => {
            const hasChildren = Boolean(option?.children?.length);
            const optionId = option?.id;
            const tooltipStringId = option?.tooltipStringId;
            return (
              <div
                key={index + option?.id}
                data-testid={option?.id}
                className={cn('option', {
                  option__active: amIActive(option),
                })}
                onClick={
                  hasChildren
                    ? isMobile
                      ? () => {
                          activateSubMenu(option);
                          setIsSubMenuOpen(true);
                          setEnableSearch(option?.enableSearch);
                        }
                      : undefined
                    : () => {
                        handleMenuItemClick(option.path);
                        isMobile && toggleSidebar();
                      }
                }
                onMouseOver={() => {
                  clearActivatedSubMenu();
                  if (hasChildren) {
                    if (!isMobile) {
                      activateSubMenu(option);
                      setEnableSearch(option?.enableSearch);
                    }
                  } else {
                    if (!isMobile && tooltipStringId) {
                      setActivatedTooltip(optionId);
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (!hasChildren && tooltipStringId && !isMobile) setActivatedTooltip(null);
                }}
              >
                {isMobile ? (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingLeft: '10px',
                    }}
                  >
                    <Box
                      component="div"
                      sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}
                    >
                      {option?.iconName ? <MaterialIcon iconName={option?.iconName} className="icon" /> : option.icon}
                      {<Translate className="label" stringId={option?.labelStringId} />}
                    </Box>
                    {hasChildren && <MaterialIcon iconName={'ArrowForwardRounded'} />}
                  </Box>
                ) : (
                  <>
                    {option?.iconName ? <MaterialIcon iconName={option?.iconName} className="icon" /> : option.icon}
                    {!hasChildren && !isMobile && tooltipStringId && activatedTooltip === optionId ? (
                      <div className="tooltip">{translateMessage(tooltipStringId)}</div>
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
        </div>

        {activatedSubMenu ? (
          isMobile ? (
            <MobileSubMenuContainer>
              <div
                className={cn('menu-sidebar', {
                  hidden: !isSubMenuOpen && isMobile,
                })}
              >
                <SubMenuContent {...subMenuProps} />
              </div>
            </MobileSubMenuContainer>
          ) : (
            <div className="menu-sidebar" onMouseLeave={() => clearActivatedSubMenu()}>
              <SubMenuContent {...subMenuProps} />
            </div>
          )
        ) : null}
      </SidebarContainer>
    );
  };

  console.log({ preparedConfig });

  return (
    <>
      {isMobile ? (
        <Drawer
          open={isSidebarOpen}
          onClose={toggleSidebar}
          sx={{ width: '267px' }}
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              overflow: 'hidden',
              borderRadius: '0 10px 10px 0',
            },
          }}
        >
          <SidebarContent />
        </Drawer>
      ) : (
        <SidebarContent />
      )}
    </>
  );
};

export default NewSidebar;
