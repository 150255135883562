import React from 'react';
import { MaterialIcon } from '../../../components/icons';
import cn from 'classnames';
import { Styled } from './styles';

const MenuIcon = ({ iconName, onClick, active, variant, className, disableClick = false }) => {
  return (
    <Styled
      className={cn({ active, disableClick, 'menu-icon-secondary': variant === 'secondary' }, className)}
      onClick={onClick}
    >
      <MaterialIcon iconName={iconName} />
    </Styled>
  );
};

export default MenuIcon;
