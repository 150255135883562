import React, { createContext, useContext, useState } from 'react';
import { usePostMessageListener } from '../../util/entity-provider/usePostMessageListener';
import { usePostMessageContext } from '../../post-message/PostMessage';

export const ChatContext = createContext();
const CHAT_CONTAINER_WIDTH = 340;

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error(`useChatContext cannot be rendered outside of the ChatContext context provider`);
  }
  return context;
};

export const ChatProvider = ({ children }) => {
  const [isChatOpened, setIsChatOpened] = useState(false);
  const { sendMessage } = usePostMessageContext();

  const handleChatClose = () => {
    setIsChatOpened(false);
    sendMessage({ type: 'close-chat' });
  };

  const handleChatOpen = () => {
    setIsChatOpened(true);
    sendMessage({ type: 'open-chat' });
  };

  const listener = React.useCallback(message => {
    if (message?.type === 'open-chat') {
      setIsChatOpened(true);
    }
    if (message?.type === 'close-chat') {
      setIsChatOpened(false);
    }
  }, []);

  usePostMessageListener(listener);

  return (
    <ChatContext.Provider
      value={{
        isChatOpened,
        handleChatClose,
        handleChatOpen,
        CHAT_CONTAINER_WIDTH,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
