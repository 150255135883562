import cn from 'classnames';
import React, { useContext } from 'react';

import { DrawerProvider } from '../../components/drawer';
import { Translate } from '../../components/localization';
import { MaterialIcon } from '../../components/icons';
import { AppSwitcher } from './app-switcher/AppSwitcher';
import { Profile } from './profile/Profile';
import MenuIcon from './menu-icon/MenuIcon';
import { AppContext } from '../../util/app-provider/context';
import EntityPicker from './entity-picker';
import { HeaderContainer } from './style';
import { useMediaQuery, Divider } from '@mui/material';
import { isProduction } from '../../util/isProduction';
import { useChatContext } from '../../components/chat';

const learningCenterAppRef = 'learning-center';
const isProd = isProduction();

export const Header = ({
  className,
  appContext,
  entityContext,
  userContext,
  onChange,
  onProfileClick,
  disableAppTray,
}) => {
  const { entitySetup } = entityContext;
  const { userApps, userAppsIsLoading, user } = userContext;
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const cdsContext = useContext(AppContext);
  const disableMobileSidebar = !cdsContext || !cdsContext.toggleSidebar;
  const { handleChatOpen, isChatOpened, handleChatClose } = useChatContext();

  const { appEntities, currentFeature, headerText, appRef } = appContext;
  const { entityRef, setEntityRef } = entityContext;

  const handleEntityChange = React.useCallback(
    (entityRef, companyName) => {
      setEntityRef(entityRef);
    },
    [setEntityRef]
  );

  return (
    <HeaderContainer className={cn({ 'is-mobile': isMobile, 'disable-mobile-sidebar': disableMobileSidebar })}>
      <DrawerProvider>
        <div className={cn('header', className)}>
          <div className={cn('left', { visible: !!currentFeature?.id })}>
            <div data-testid="feature-name" className={cn('feature-name')}>
              {currentFeature?.iconName ? (
                <MaterialIcon iconName={currentFeature?.iconName} className="feature-icon" />
              ) : null}
              {headerText ||
                (currentFeature ? (
                  <Translate data-testid="feature-title" stringId={currentFeature.labelStringId} />
                ) : (
                  ''
                ))}
            </div>
          </div>
          {isMobile && cdsContext && <Divider component="hr" className="header-divider" aria-hidden="true" />}
          <div className="right">
            <div className="center-aligned">
              {!disableMobileSidebar && cdsContext.toggleSidebar && (
                <MenuIcon
                  iconName="Menu"
                  onClick={cdsContext.toggleSidebar}
                  variant="secondary"
                  className={cn('mobile-menu-icon')}
                />
              )}
              {!disableAppTray && (
                <>
                  {appEntities?.length > 0 && (
                    <EntityPicker
                      currentEntitySetup={entitySetup}
                      value={entityRef}
                      onChange={handleEntityChange}
                      entities={appEntities}
                      className="entity-picker"
                    />
                  )}
                  <MenuIcon
                    iconName="HelpOutlineOutlined"
                    active={appRef === learningCenterAppRef}
                    onClick={() => onChange(learningCenterAppRef, true)}
                    variant="secondary"
                    disableClick={appRef === learningCenterAppRef}
                  />
                  {!isProd && (
                    <MenuIcon
                      active={isChatOpened}
                      iconName="MarkUnreadChatAltOutlined"
                      onClick={isChatOpened ? handleChatClose : handleChatOpen}
                      variant="secondary"
                    />
                  )}
                  <AppSwitcher
                    onChange={onChange}
                    userApps={userApps}
                    isLoading={userAppsIsLoading}
                    appContext={appContext}
                  />
                </>
              )}
              <Profile onClick={onProfileClick} user={user} />
            </div>
          </div>
        </div>
      </DrawerProvider>
    </HeaderContainer>
  );
};
